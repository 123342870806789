import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Box, CircularProgress, Stack, styled } from '@mui/material';
// components
//
import './main.css';
import MainFooter2 from './MainFooter2';
import MainHeader from './MainHeader';
import useAuth from '../../hooks/useAuth';
// ----------------------------------------------------------------------
const CirculerSection = styled(Box)(() => ({
  position: "fixed",
  left: "50%",
  top: "50%",
  width: "100%",
  height: "100%",
  zIndex: 9999
}))

export default function MainLayout() {
  const { isAuthenticated, user } = useAuth()
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      {isAuthenticated && !user?.id &&
        <CirculerSection>
          <CircularProgress />
        </CirculerSection>
      }


      <Outlet />

      <Box sx={{ flexGrow: 1 }} />


      <MainFooter2 />

    </Stack>
  );
}
