// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const BLOG_DOMAIN = 'https://blog.impaac.org';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
};

export const BLOG_PATH = {
  root: BLOG_DOMAIN,
  blog: path(BLOG_DOMAIN, '/'),
  faqs: path(BLOG_DOMAIN, '/faq'),
  privacypolicy: path(BLOG_DOMAIN, '/privacy-policy'),
  termsAndconditions: path(BLOG_DOMAIN, '/terms-conditions'),
  howItWorks: path(BLOG_DOMAIN, '/how-it-works'),
  contact: path(BLOG_DOMAIN, '/contact'),
};
export const PATH_PAGE = {
  campaignView: (id, title) => `/fundraisers/${title}/${id}`,
  ngoView: (id) => `/ngo/${id}`,
  paymentSuccessful: (id) => `/donation-successful/${id}`,
  paymentClaim: (id) => `/donation-claim/${id}`,
  campaignEdit: (id, title) => `/fundraisers/${title}/${id}/edit`,
  withdrawalCreate: (id) => `/withdrawal/create/${id}`,
  campaignNew: '/fundraisers/new',
  campaign: '/campaign',
  medical: '/medical',
  relief: '/relief',
  env: '/environment',
  poverty: '/poverty',
  csr: '/csr',
  education: '/education',
  charityplatform: '/WeAreCharityPlatform',
  animal: '/animal',
  ngo: '/ngo',
  maintenance: '/maintenance',
  about: '/about',
  contact: '/contact',
  profileComplete: '/profileComplete',
  faqs: '/faqs',
  blog: '/story',
  howItWorks: '/how-it-works',
  linktree: '/linktree',
  privacypolicy: '/privacy-policy',
  terms: '/terms-conditions',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    campaign: path(ROOTS_DASHBOARD, '/campaign'),
    donation: path(ROOTS_DASHBOARD, '/donation'),
    withdrawal: path(ROOTS_DASHBOARD, '/withdrawal'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  donation: {
    root: path(ROOTS_DASHBOARD, '/donation'),
    list: path(ROOTS_DASHBOARD, '/donation/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/donation/${id}`),
  },
  withdrawal: {
    root: path(ROOTS_DASHBOARD, '/withdrawal'),
    create: (id) => path(ROOTS_DASHBOARD, `/withdrawal/create/${id}`),
    list: path(ROOTS_DASHBOARD, '/withdrawal/list'),
    // view: (id) => path(ROOTS_DASHBOARD, `/donation/${id}`)
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  organisation: {
    root: path(ROOTS_DASHBOARD, '/organisation'),
    profile: path(ROOTS_DASHBOARD, '/organisation/profile'),
    account: path(ROOTS_DASHBOARD, '/organisation/account'),
    new: path(ROOTS_DASHBOARD, '/organisation/new'),
  },
};

export const PATH_DOCS = 'https://www.impaac.org/';
