import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

// Analytics
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import CircularLoader from './components/CircularLoader';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import { Ga } from './Analytic';
import ClarityScript from './utils/clarity';


const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM
}

TagManager.initialize(tagManagerArgs)

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

// ReactPixel.init('760140411766493', options);
ReactPixel.init(process.env.REACT_APP_PIXEL_ID, options);

// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();
  useEffect(() => {
    Ga()
    ReactPixel.pageView();

    const searchParams = new URLSearchParams(window.location.search);
    const refParam = searchParams.get('ref');
    if (refParam?.length === 6) {
      Cookies.set('ref', refParam);
    }

  }, [location])


  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <CircularLoader />
          <ClarityScript />
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
